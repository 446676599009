<template>
  <div class="container mt-4">
    <h1>Thank You!</h1>
    <p>
      We have received your donation. </p>
  </div>
</template>

<script>
  export default {
    name: 'ThankYou',
    mounted () {

    }
  }
</script>

<style scoped>

</style>

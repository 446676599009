<template>
  <div class="mt-2 mt-md-5" role="form">
    <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
    <b-form @submit.prevent="onSubmit" @reset="onReset">
      <b-form-group id="input-group-email" label-for="email"
                    description="We'll never share your email with anyone else.">
        <slot name="label">Email address: <span class="asterisk">*</span></slot>
        <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-first-name" label="First Name:" label-for="first-name">
        <b-form-input id="first-name" v-model="form.first_name" type="text" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-last-name" label="Last Name:" label-for="last-name">
        <b-form-input id="last-name" v-model="form.last_name" type="text" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-question" label="Question (optional):" label-for="question">
        <b-form-textarea id="question" v-model="form.question"></b-form-textarea>
      </b-form-group>

      <div style="position: absolute; left: -5000px;" aria-hidden="true">
        <input type="text" name="b_622da9a94f2b448be1c8a267e_7c6be538b7" tabindex="-1" value="">
      </div>

      <b-form-group id="interestgroup_field">
        <template v-slot:label>
          Marketing Permissions
          <p>Please select all the ways you would like to hear from REGENiTECH:</p>
        </template>

        <b-form-checkbox id="gdpr_email" v-model="form.gdpr_email" name="gdpr[92357]" value="Y" unchecked-value="">
          Email
        </b-form-checkbox>

        <b-form-checkbox id="gdpr_direct_mail" v-model="form.gdpr_direct_mail" name="gdpr[92361]" value="Y"
                         unchecked-value="">
          Direct Mail
        </b-form-checkbox>

        <b-form-checkbox id="gdpr_online_advertising" v-model="form.gdpr_online_advertising" name="gdpr[92365]"
                         value="Y" unchecked-value="">
          Customized Online Advertising
        </b-form-checkbox>
      </b-form-group>

      <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our
        privacy practices, please visit our website.</p>

      <div class="content__gdprLegal">
        <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your
          information will be transferred to Mailchimp for processing.
          <a href="https://mailchimp.com/legal/" target="_blank">
            Learn more about Mailchimp's privacy practices here.
          </a>
        </p>
      </div>

      <b-button-toolbar key-nav aria-label="Form buttons">
        <b-button type="submit" variant="primary" class="mr-2">Subscribe</b-button>
        <!--        <b-button type="reset" variant="secondary">Reset</b-button>-->
      </b-button-toolbar>
    </b-form>

    <b-card class="mt-3" header="Form Data Result" v-if="false">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'EmailUsForm',
    data () {
      return {
        form: {
          first_name: '',
          last_name: '',
          email: '',
          server: 'regenitech.com',
          question: '',
          gdpr_email: '',
          gdpr_direct_mail: '',
          gdpr_online_advertising: ''
        }
      }
    },
    computed: {
      ...mapState(['formCaptureApi'])
    },
    methods: {
      ...mapActions(['submitForm']),
      onSubmit () {
        // let url = encodeURI(`mailto:info@regenitech.com?subject=I would like more information&body=My name is: ${first} ${last}\nMy question is: ${question}`)
        // window.open(url)
        this.submitForm(this.form)
      },
      onReset () {
        this.form.first_name = ''
        this.form.last_name = ''
        this.form.email = ''
        this.form.server = 'regenitech.com'
        this.form.question = ''
      }
    }
  }
</script>

<style lang="scss">
  .indicates-required {
    text-align: right;
    font-size: 11px;
    margin-right: 0;
  }

  .asterisk {
    color: #e85c41;
    font-size: 150%;
    font-weight: normal;
    position: relative;
    top: 5px;
  }

  .vcf-container {
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0 0 12px $gray-400;

    .vcf-messages > div {
      width: 100% !important;
    }

    .vcf-input-container,
    .vcf-input {
      border-radius: 0 0 10px 10px;
    }

    .vcf-send-button,
    .vcf-messages > div > div.vcf-question span {
      background: linear-gradient(to right bottom, $teal-8, $teal-1) !important;
      color: $white;
    }
  }
</style>

<template>
  <div class="container flex-column align-items-center mb-5">
    <div class="row">
      <div class="col-md-4 offset-md-1 col-12">
        <h1 class="subtitle mt-5">Contact Us</h1>
        <p>Please use the form to send us your information and we'll get back to you and try to help as soon as we
          can.</p>
      </div>
      <div class="col-md-6 col-12">
        <email-us-form v-if="!formSubmitted"></email-us-form>
        <div v-else>
          <div class="thank-you-box d-flex flex-column align-items-center justify-content-center">
            <h2>Thank you</h2>
            <p>We will get back to you as soon as we can.</p>
            <b-button variant="secondary" pill @click="clearForm">Reset Form</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import EmailUsForm from '@/components/EmailUsFormMC'

  export default {
    name: 'ContactUs',
    computed: {
      ...mapState(['formSubmitted'])
    },
    components: {
      EmailUsForm
    },
    methods: {
      clearForm () {
        this.$store.state.formSubmitted = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .thank-you-box {
    border: 1px solid $gray-400;
    padding: 12px;
    margin: 48px 40px;
    border-radius: 12px;
    min-height: 500px;
    height: 400px;
    width: 460px;
  }
</style>
